article h4 {
  letter-spacing: 0.1rem;
  text-transform: capitalize;
  line-height: 1.25;
  margin-bottom: 0.75rem;
}

.section {
  display: flex;
  width: 90vw;
  margin: 5rem auto;
  max-width: 1170px;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
    height: 40vh;
  }
}

.icon {
  font-size: 3rem;
  color: hsl(21, 62%, 45%);
}

.section-center {
  margin: 0 auto;
  margin-top: 4rem;
  width: 80vw;
  height: 100%;
  max-width: 800px;
  text-align: center;
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
}

article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.3s linear;
}

article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}

article.lastSlide {
  transform: translateX(-100%);
}

article.nextSlide {
  transform: translateX(100%);
}

.person-img {
  border-radius: 50%;
  margin-bottom: 1rem;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 4px solid hsl(210, 31%, 80%);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

article h4 {
  text-transform: uppercase;
  color: hsl(21, 62%, 45%);
  margin-bottom: 0.25rem;
}

.text {
  max-width: 35em;
  margin: 0 auto;
  margin-top: 2rem;
  line-height: 2;
  color: #000;
  font-size: 1rem;
}

.prev:hover,
.next:hover {
  background: rgba(0, 0, 0, 0.04);
}

.prev,
.next {
  position: absolute;
  background: none;
  color: rgb(235, 176, 97);
  width: 1.25rem;
  height: 1.25rem;
  display: grid;
  place-items: center;
  border-color: transparent;
  font-size: 1.2rem;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: all 0.3s linear;
}

.prev {
  top: 380px;
  left: 15px;
}

.next {
  top: 380px;
  right: 10px;
}

@media (min-width: 800px) {
  .text {
    max-width: 45em;
    font-size: 1.1rem;
  }

  .prev,
  .next {
    width: 2rem;
    height: 2rem;
    font-size: 1.2rem;
    top: 220px;
  }

  .prev {
    left: 180px;
  }

  .next {
    right: 180px;
  }
}

@media (min-width: 1000px) {
  .prev {
    top: 250px;
    left: 200px;
  }

  .next {
    top: 250px;
    right: 200px;
  }
}
