.team-section {
  margin-top: 100px;
  padding: 5% 5%;
  margin-bottom: 30px;
}

.top-line {
  background-color: #cbc0ae;
  width: 100%;
  height: 2px;
  margin: 20px auto;
}

.team-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.team-title {
  text-align: center;
  font-size: 30px;
  margin-bottom: 20px;
  color: #000;
  font-family: neue-haas-grotesk-display, sans-serif;
}

.team-img {
  width: 100%;
}

.team-members a {
  text-decoration: none;
  color: #c7a46c;
}

.flex-container {
  max-width: 100%;
  position: relative;
  top: 300px;
  display: flex;
}

.image-container {
  width: 20%;
}

.text-container {
  width: 60%;
}

@media only screen and (min-width: 1050px) {
  .team-container {
    width: 90%;
    padding: 30px 5%;
  }
}
