.research-section {
  margin: 0 auto;
  margin-top: calc(10%);
  display: flex;
  flex-direction: column;
  width: 80%;
}

.research-section > .categories {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  border: none;
}

.research-indi {
  margin: 40px auto;
  margin-top: calc(10% + 40px);
  margin-bottom: 40px;
  border-bottom: 1px solid #cbc0ae;
  padding-bottom: 20px;
  width: 70%;
}

.research-title {
  font-weight: 800;
  font-size: 1.8rem;
  font-family: minion-pro-caption, serif;
  text-align: center;
}

.research-title,
.research-workplan-title {
  margin-top: 30px;
  margin-bottom: 20px;
}

.research-description,
.research-workplan {
  font-size: 16px;
  line-height: 1.3;
}

.research-author {
  color: hsl(21, 62%, 45%);
  margin-bottom: 20px;
}

.research-objectives {
  font-weight: 600;
}

.research-objectives li {
  line-height: 1.5;
}

.research-workplan-title {
  font-size: 1.1rem;
  font-weight: 600;
}

.limit-text {
  margin-top: 20px;
  width: 300px;
  text-align: center;
}

@media only screen and (max-width: 800px) {
  .research-section {
    flex-direction: column;
  }
  .research-section > .categories {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .research-flex {
    display: flex;
    flex-direction: column;
  }

  .categories > div > label {
    display: block;
    font-size: 0.8rem;
  }
  .categories > div {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .research-author {
    text-align: center;
  }

  .research-description,
  .research-workplan {
    line-height: 30px;
    font-size: 14px;
  }

  .research-indi {
    margin-top: 50px;
    padding: 0 5%;
  }

  .limit-text {
    margin-top: 20px;
    width: 100%;
    text-align: center;
    font-size: 14px;
    line-height: 1.4;
  }

  .research-card-author {
    font-size: 15px;
  }

  .research-card {
    width: 100%;
  }

  .research-objectives li {
    line-height: 1.5;
    font-size: 14px;
  }

  .research-objectives {
    padding-left: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .research-section > .categories {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
