.front-page {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  margin: 0 2.5%;
  height: 70vh;
}

.front-title {
  font-size: 0.5rem;
  color: grey;
}

body {
  overflow-x: hidden;
  min-height: 100%;
}

i {
  font-style: italic;
}
