.activities-container {
  width: 100%;
  padding-top: 2rem;
  display: flex;
  justify-content: center;
}

.column {
  width: 70%;
  height: 100%;
  min-height: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.filters-tweets-container {
  position: relative;
  top: calc(10vh + 60px);
  display: flex;
  margin-top: 30px;
  margin-bottom: 150px;
}

.filters-container {
  position: fixed;
  z-index: 8;
  width: 15%;
  max-height: 10px;
  border-radius: 10px;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.tweets-container {
  position: fixed;
  width: 20%;
  max-height: 400px;
  right: 2.5%;
}

/* Upcomming events */

.upcomming-wrapper {
  width: 90%;
  height: 220px;
  border: 1px solid #cbb084;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: 10px;
}

.upcomming-title {
  width: 100%;
  text-align: center;
  font-weight: 900;
  margin: 10px 0px;
  color: #cbc0ae;
}

.upcomming {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 20px;
  padding: 10px;
  overflow: auto;
  overflow-y: hidden;
}

.upcomming-card {
  display: flex;
  gap: 0.5rem;
  border: 1px solid #cbc0ae;
  width: 400px;
  height: 200px;
  border-radius: 10px;
  padding: 5px 5px;
  justify-content: space-between;
  overflow: hidden;
}

.upcomming-card:hover {
  box-shadow: #cbc0ae 0px 3px 8px;
  cursor: pointer;
}

.upcomming-card-img {
  max-width: 50%;
  height: 100%;
  overflow: hidden;
  display: flex;
}

.upcomming-card-img > img {
  min-width: 100%;
  border-radius: 10px;
  justify-self: flex-end;
}

.upcomming-card-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.upcomming-card-info p {
  min-width: 50%;
  display: flex;
  align-items: center;
}

.upcomming-card h4 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #cbc0ae;
}

.upcomming-card p {
  font-size: 0.7rem;
  font-weight: 600;
  color: #cbc0ae;
}

/* Filters Styling */
.filters-container > form {
  user-select: none;
}

.filters {
  justify-self: flex-start;
  margin-left: 30px;
}

.filters > h2 {
  background-color: #cbb084;
  color: #fff;
  padding: 5px;
  border-radius: 2px;
  user-select: none;
}

.filters > h2:hover {
  background-color: #edcd99;
  cursor: pointer;
}

input[type="radio"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  width: 0.9em;
  height: 0.9em;
  border: 1px solid #cbc0ae;
  border-radius: 50%;
}

input[type="radio"]:checked {
  -webkit-appearance: none;
  appearance: none;
  background-color: #cbc0ae;
  width: 0.9em;
  height: 0.9em;
  border: 1px solid #cbc0ae;
  border-radius: 50%;
}

.categories {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  border-top: 1px solid #cbc0ae;
  line-height: 25px;
}

/* Twitter Styling */

.twitterContainer,
.twitter-embed {
  border-radius: 10px;
  overflow: auto;
}

.twitterContainer::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

/* Cards for content */

.content-card-container {
  width: clamp(10%, 800px, 90%);
  max-width: 800px;
  min-height: 312px;
  max-height: 450px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  border-radius: 10px;
  border: 1px solid #cbc0ae;
  margin-bottom: 50px;
  overflow: hidden;
}

.content-card-container:hover {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  cursor: pointer;
}

.content-img {
  flex-shrink: 0;
  min-height: 100%;
  max-width: 50%;
  overflow: hidden;
  border-radius: 0px 10px 10px 0;
}

.content-card-container img {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  align-self: center;
}
/* .research-card img {
  position: relative;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10%;
  align-self: center;
} */

.content-card-info,
.categories-publishdate {
  display: flex;
  flex-direction: column;
}

.content-card-info {
  width: 50%;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  justify-content: center;
}

.content-card-info > h2 {
  font-family: minion-pro-caption, serif;
  font-style: normal;
  font-size: 1.8rem;
  font-weight: 800;
  margin-bottom: 20px;
  text-align: center;
}

.categories-publishdate {
  display: flex;
  height: 60px;
  justify-content: space-between;
  flex-direction: column-reverse;
  line-height: 20px;
  text-align: center;
  margin-top: 10px;
}

.categories-publishdate > p {
  color: #000;
}

/* activities Pupup */
.close {
  margin-bottom: 10px;
  width: 20px;
  height: 20px;
  border-radius: 5px;
  background-color: #edcd99;
  align-self: flex-start;
}
.close:hover {
  background-color: #ac956f;
  cursor: pointer;
}

.activity-card-popup {
  position: fixed;
  top: -50%;
  left: -50%;
  transform: translate(24%, 43%);
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.282);
}

.flex-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  max-width: 1250px;
  height: 70%;
  max-height: 1000px;
  padding: 20px;
  background-color: #f8f2ee;
  border: 2px solid #cbc0ae;
  border-radius: 10px;
}

.popup-header {
  max-width: 200px;
  max-height: 100%;
  display: flex;
  flex-direction: column;
}

.header-info {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
}

.popup-header img {
  width: 100%;
  max-width: 300px;
  justify-self: flex-end;
  display: none;
}

.popup-date-author {
  color: #a89982;
  margin-bottom: 10px;
  font-size: 20px;
  font-size: clamp(0rem, calc(30vw / 7), 1rem);
}

.popup-main-text {
  /* margin: 0 50px; */
  padding-right: 20px;
  margin-top: 70px;
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  line-height: 22px;
  font-size: 18px;
}

.popup-main-text h2 {
  text-align: center;
  line-height: 30px;
  font-family: minion-pro-caption, serif;
  font-weight: 800;
  max-width: 400px;
  font-size: clamp(2rem, calc(30vw / 7), 1.8rem);
  margin-bottom: 20px;
}

strong {
  font-weight: bold !important;
}

.underline {
text-decoration: underline;
}

a {
  text-decoration: none;
  color: #cbc0ae;
  font-size: 0.7rem;
}

@media screen and (max-width: 600px) {
  .content-img > img {
    display: none;
  }
  #tags {
    display: none;
  }
  #cardTitle {
    font-size: 1.5rem;
    width: 100%;
  }
  #date {
    display: none;
  }
}

@media screen and (min-width: 860px) {
  .header-info {
    align-self: flex-start;
  }

  .popup-header {
    text-align: left;
    height: 85%;
  }

  .popup-content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .popup-header img {
    display: block;
  }
  /* research */
  .filters-container {
    left: 0%;
  }

  #research-pop-title {
    width: 80%;
    max-width: none;
    text-align: center;
  }
}
@media screen and (max-width: 1000px) {
  .column {
    width: 100%;
  }

  .filters-container {
    position: absolute;
    width: 100%;
    top: -20px;
    padding: 0;
  }
  /* .filters-tweets-container {
    display: none;
  } */

  .filters {
    margin: 0px;
    align-items: center;
  }

  .filters > form {
    background-color: #f8f2ee;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
  }

  .activities-container {
    grid-template-columns: 0 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .twitterContainer {
    display: none;
  }
}

@media screen and (min-width: 1000px) {
  .activities-container {
    position: relative;
    left: 20px;
    width: 90%;
  }
}
