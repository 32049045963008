.top-line {
  background-color: #cbc0ae;
  width: 100%;
  height: 2px;
  margin: 20px auto;
}

.member-section {
  margin-top: 50px;
  padding: 0 5%;
}

.singleteam-img {
  width: 100%;
  max-width: 300px;
}

.singleteam-name {
  margin: 30px 0;
  font-size: 25px;
}

.singleteam-container {
  display: flex;
  flex-direction: column;
}

.singleteam-container p {
  font-size: 15px;
  color: #000;
  line-height: 23px;
  margin: 0 0 10px;
}

.singleteam-container div p span {
  font-size: 0.6rem;
  font-style: italic;
}

.back-btn {
  cursor: pointer;
  font-size: 1.5rem;
  color: #000;
}

.back-btn.active::before {
  width: 0px;
}

.orcid {
  text-decoration: none;
  color: #000;
  font-size: 1rem;
}

.orcid img {
  position: relative;
  top: 2px;
  margin-right: 3px;
}

.contact {
  font-size: 1.3rem;
}

.contact-container {
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.modal-container {
  position: absolute;
  top: 52px;
  border: none;
  background-color: #f8f2ee;
  height: 200vh;
}

@media only screen and (min-width: 600px) {
  .modal-container {
    position: absolute;
    top: 52px;
    border: none;
    background-color: #f8f2ee;
  }
}

@media only screen and (min-width: 1150px) {
  
  .modal-container {
    top: calc(10% + 60px);
    padding: 3% 10%;
  }

  .singleteam-container {
    flex-direction: row;
  }

  .singleteam-container p {
    order: -1;
  }

  .singleteam-img {
    width: 300px;
  }

  .top-line {
    display: none;
  }

  .contact-container {
    align-items: flex-end;
  }


}

@media only screen and (min-width: 1700px) {
  .singleteam-container p {
    width: 400%;
  }
}
