.contact-section {
  margin-top: 100px;
}
.contact-flex {
  margin-top: 50px;
  min-width: 400px;
  height: 300px;
  margin: 0 auto;
  border: 1px solid #cbb084;
  border-radius: 10px;
  padding: 20px;
  max-height: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.location-container {
  display: flex;
  flex-direction: column;
  min-height: 200px;
}

.contact-title {
  position: relative;
  bottom: 100px;
  text-align: center;
  font-size: 30px;
  margin-bottom: 20px;
  color: #000;
  font-family: neue-haas-grotesk-display, sans-serif;
  font-weight: 400;
}

.mail-container {
  display: grid;
  gap: 10px;
}

.contact-text {
  line-height: 1.4;
}

.contact-subtitle {
  font-weight: 600;
  font-size: 1.2rem;
}

.mail-link {
  font-size: 1rem;
  color: #cbb084;
}

.contact-image {
  display: none;
}

@media only screen and (min-width: 800px) {
  .contact-subtitle:nth-child(1) {
    margin-top: 30px;
  }

  .contact-image {
    display: block;
    width: auto;
    height: 300px;
  }

  .contact-flex {
    margin: 0;
    max-height: 250px;
  }

  .location-container {
    height: auto;
  }
}
