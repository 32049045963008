.member-img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  -webkit-box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.5);
}

.member-name {
  font-size: 1rem;
  color: #000;
  margin: 10px 0;
  font-weight: 400;
  text-align: center;
}

.member-title {
  font-size: 0.9rem;
  color: hsl(21, 68%, 58%);
}

.member-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.member-box {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.member-box:hover {
  transition: 0.3s;
  transform: scale(1.1);
}

.member-div {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 50px;
}

@media only screen and (min-width: 500px) {
  .member-img {
    width: calc(150px);
    height: calc(150px);
  }
}

@media only screen and (min-width: 750px) {
  .team-section {
    margin-top: calc(10% + 30px);
  }

  .member-img {
    width: 200px;
    height: 200px;
  }

  .member-name {
    font-size: 1.3rem;
    color: #000;
    margin: 10px 0;
  }

  .member-title {
    font-size: 1.2rem;
  }

  .team-members a {
    width: 100%;
  }

  .member-div {
    gap: 55px;
    display: flex;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 50px;
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media only screen and (min-width: 1050px) {
  /* .team-img {
        margin: 0 auto;
        width: 80%;
    }  */

  .member-div {
    display: flex;
    margin-bottom: 50px;
  }
}
@media only screen and (min-width: 1350px) {
  .team-img {
    width: 70%;
  }
}
