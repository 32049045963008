.accordion-container {
  max-width: 95%;
  margin: 1rem auto;
  position: relative;
  margin-top: calc(10% + 60px);
}

.publication-year {
  text-align: center;
  font-size: 3rem;
  font-family: minion-pro-caption, serif;
  font-weight: 700;
  font-style: normal;
  margin: 50px;
}

.accordion {
  max-width: 95%;
  margin: 1rem auto;
  position: relative;
}

.accordion > h2 {
  text-align: center;
}

.accordion-item {
  overflow: hidden;
}

.accordion-title {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  border-bottom: 1px solid #cbc0ae;
  background-color: #f8f2ee;
}

.accordion-title:hover {
  background-color: #efe0c8;
}

.author {
  margin-left: 10px;
  color: hsl(21, 62%, 45%);
}

.accordion-content {
  width: 100%;
  transform: translateY(0);
}

.fadein {
  position: relative;
  z-index: -1;
  animation: foldout 1s ease-in-out backwards;
}

@keyframes foldout {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}

.accordion-header {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #cbc0ae;
  overflow: hidden;
}

.accordion-header img {
  position: relative;
  left: 25%;
  bottom: -10px;
  width: 10%;
  height: 10%;
}

.accordion-header h1 {
  width: 50%;
  font-family: minion-pro-caption, serif;
  font-style: normal;
  font-size: clamp(1rem, calc(50vw / 7), 2rem);
  font-weight: 800;
  margin-bottom: 20px;
}

.publcation-content {
  width: 100%;
  display: flex;
}

.publcation-content div {
  width: 90%;
  margin-top: 25px;
}

.publication-text {
  line-height: 20px;
  font-size: 18px;
  text-align: left;
  gap: 2rem;
  display: flex;
}

.accordion-title,
.accordion-content {
  padding: 1rem;
}

/* .accordion-content {
    background-color: #39b9d2;
  } */

@media screen and (max-width: 700px) {
  body {
    font-size: 18px;
  }
  /* .accordion-header > img {
    display: none;
  } */

  .accordion {
    width: 90%;
  }
}
@media screen and (max-width: 900px) {
  body {
    font-size: 18px;
  }

  .accordion-header > img {
    left: 20px;
    width: 40%;
  }

  .accordion {
    width: 90%;
  }
}
