.container-slider {
  max-width: 700px;
  height: 500px;
  margin: 100px auto 0;
  position: relative;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 700px) {
  .container-slider {
    margin: 100px 10px 0;
  }
}

.loading-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f2ee;
}

.emblem {
  height: 50vh;
}

.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  transition: opacity ease-in-out 0.4s;
}

.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.active-anim {
  opacity: 1;
}

.btn-slide {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #f1f1f1;
  border: 1px solid rgba(34, 34, 34, 0.287);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-slide img {
  width: 25px;
  height: 25px;
  pointer-events: none;
}

.container-dots {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}

.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #f1f1f1;
  margin: 0 5px;
  background: #f1f1f1;
}

.dot.active {
  background: rgb(32, 32, 32);
}

.front-page {
  flex-direction: column;
}

.left-l,
.right-l {
  display: none;
}

@media screen and (min-width: 800px) {
  .front-page {
    height: 80vh;
    position: relative;
    top: calc(60px);
  }

  .right-l,
  .left-l {
    position: relative;
    display: block;
    align-self: center;
    height: auto;
    width: 150px;
    top: 100px;
  }

  .right-l {
    transform: rotate(-20deg);
  }

  .left-l {
    transform: rotate(20deg);
  }
}

@media screen and (min-width: 1200px) {
  .front-page {
    height: 80vh;
    position: relative;
    top: calc(60px);
  }

  .right-l,
  .left-l {
    position: relative;
    display: block;
    align-self: center;
    height: auto;
    width: 200px;
    top: 50px;
  }

  .right-l {
    left: -50px;
    transform: rotate(-20deg);
  }

  .left-l {
    left: 50px;
    transform: rotate(20deg);
  }
}
