header {
  width: 100vw;
  display: flex;
  justify-content: center;
}

/* Mobil stylings */
.nav-container {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: #f1e8d9;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  transform: translateX(100%);
  transition: 0.3s ease-in;
  z-index: 10;
}

.nav-links {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.nav-links a {
  text-decoration: none;
  color: #000;
  font-size: 1.9rem;
  margin-top: 20px;
}

.logo {
  width: 150px;
  justify-self: start;
  margin-bottom: 100px;
}

.nav-container h1 {
  display: none;
}

.nav-active {
  transform: translateX(0);
}

.toggle .lineone {
  transform: rotate(-45deg) translate(7px, -6px);
  width: 2.3rem;
}

.toggle .linetwo {
  opacity: 0;
}

.toggle .linethree {
  transform: rotate(45deg);
}

.nav {
  top: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 8vh;
  background-color: #cbc0ae;
}

.nav-logo {
  position: relative;
  width: 25px;
  padding-left: 25px;
  z-index: 20;
}

.nav-toggler div {
  width: 2.3rem;
  height: 0.15rem;
  margin: 0.4rem;
  background: #cbc0ae;
  position: fixed;
  right: 20px;
  top: 1.6rem;
  border-radius: 19%;
  transition: 0.05s ease-in;
}

.nav-toggler div:nth-child(2) {
  top: 0.4rem;
  width: 2.3rem;
}

.nav-toggler div:nth-child(3) {
  top: 1rem;
  width: 2.3rem;
}

.nav-toggler {
  position: fixed;
  right: 20px;
  top: 0.8rem;
  z-index: 11;
  cursor: pointer;
  height: 30px;
  width: 50px;
}

@media only screen and (min-width: 1150px) {
  .nav-toggler {
    display: none;
  }

  nav {
    width: 100vw;
    height: 10%;
    border-bottom: 1px solid #cbc0ae;
  }

  .nav-container h1 {
    font-family: minion-pro-caption, serif;
    font-style: normal;
    font-size: 1.3rem;
    font-weight: 800;
    position: absolute;
    left: 20%;
    display: block;
  }

  .nav-links {
    flex-direction: row;
    display: block;
  }

  .logo {
    padding-left: 2%;
    width: 30%;
    margin: 0;
  }

  .nav-container {
    transform: translateX(0);
    position: fixed;
    padding-right: 2%;
    width: 100%;
    height: 10%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    background: #f8f2ee;
    padding: 30px 0 30px 0;
    border-bottom: 1px solid #cbc0ae;
  }

  .nav-title {
    margin-left: 10px;
    font-size: 0.8rem;
  }

  .nav-container a {
    text-decoration: none;
    color: #000;
    margin-right: 14px;
    font-size: 0.9rem;
  }

  .active::before {
    content: "";
    width: 5px;
    height: 5px;
    background-color: grey;
    display: inline-block;
    border-radius: 50%;
    margin-right: 3px;
    position: relative;
    bottom: 2px;
    font-size: 0.8rem;
  }
}

@media only screen and (min-width: 1000px) {
  .nav-container h1 {
    left: calc(50% - 104.5px);
  }
}
