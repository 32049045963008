footer {
  position: relative;
  display: flex;
  justify-content: space-between;
  max-width: 100vw;
  height: 100px;
  padding: 0.5% 3%;
  border-top: 1px solid #cbc0ae;
  z-index: 9;
}

.supported-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 40%;
  font-size: 12px;
  color: #000;
}

.supported-container > img {
  width: 13%;
}

.info-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 30%;
  font-size: 12px;
  color: #000;
}

.some-container {
  display: flex;
  align-items: center;
  margin: 10px;
  gap: 1rem;
  justify-content: center;
  justify-content: flex-end;
  width: 30%;
  font-size: 12px;
  color: #000;
}

.some-container > a > p {
  font-size: 12px;
  color: #000;
}

.some-logo {
  padding: 10px 10px;
  width: 20px;
}

@media screen and (max-width: 1300px) {
  footer {
    flex-direction: column;
  }

  .supported-container,
  .info-container {
    width: 100%;
    justify-content: center;
  }

  .some-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    order: 1;
  }

  .some-logo {
    padding: 10px 10px;
    width: 20px;
  }
}

@media screen and (max-width: 650px) {
  .info-container {
    flex-direction: row;
    justify-content: center;
  }
}
